@import '@gupy/design-system/Styles/variables/index';

.infotip-copilot {
  text-align: center;
  position: fixed;
  bottom: 70px;
  right: 160px;
  padding: 16px;
  background-color: #ffffff;
  border: solid 1px #eee;
  max-width: 210px;
  border-radius: 50px 50px 0 50px;
  box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.12);

  &.open {
    animation: showMessage 0.1s ease forwards;
  }

  &.closing {
    animation: closeMessage 0.1s ease forwards;
  }

  @keyframes showMessage {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes closeMessage {
    to {
      opacity: 0;
    }
  }
}
.button-open-copilot {
  z-index: 150;

  display: flex;
  position: fixed;
  cursor: pointer;
  bottom: 20px;
  right: 100px;
  width: 60px;
  height: 60px;
  border-radius: 50%;

  @media only screen and (max-width: $screen-md) {
    bottom: 100px;
    right: 20px;
  }

  .icon-button {
    width: 60px;
    height: 60px;
    padding: 10px;
    background-color: #007bff;
    border-radius: 50%;
    cursor: pointer;

    &:hover {
      background-color: #1252af;
    }

    &:active {
      background-color: #0d3d83;
    }
  }
}

.copilot {
  z-index: 11;

  background-color: #ffff;
  height: 89%;
  display: flex;
  flex-direction: column;
  position: fixed;
  max-width: 384px;
  right: 0;
  bottom: 0;
  padding: 16px;
  box-shadow: 0px 12px 40px 0px #0000001a;

  &.open {
    animation: showCopilot 0.2s forwards;
  }

  &.closing {
    animation: closeCopilot 0.2s forwards;
  }
  @keyframes showCopilot {
    from {
      width: 0;
    }
    to {
      opacity: 1;
      width: 384px;
    }
  }

  @keyframes closeCopilot {
    from {
      opacity: 1;
      width: 384px;
    }
    to {
      width: 0;
    }
  }

  .copilot-content {
    .header {
      padding: 16px 0;
      .content-header {
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

        .title-header {
          display: flex;
          align-items: center;
          flex-direction: row;

          h3 {
            margin: 0;
            font-family: Inter;
            padding: 0 12px;
            font-size: 20px;
            font-weight: 700;
            line-height: 30px;
            text-align: left;
          }
        }
        .button-close-copilot {
          width: 44px;
          height: 44px;
          padding: 0px 24px 0px 24px;
          gap: 8px;
          border-radius: 32px 0px 0px 0px;
          opacity: 0px;

          display: flex;
          border: none;
          padding: 10px;
          cursor: pointer;
          flex-shrink: 0;
        }
      }
    }

    .chatbox-body {
      padding: 4px 8px 16px;
      height: 400px;

      @media only screen and (min-height: 800px) {
        height: 535px;
      }

      overflow-y: scroll;
      overflow-x: hidden;

      opacity: 0;
      transform: translateX(20px);

      animation: slide 0.4s forwards ease 0.2s;

      @keyframes slide {
        to {
          transform: translateX(0);
          opacity: 1;
        }
      }
      &::-webkit-scrollbar {
        width: 4px;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        background-color: #9b9a9b;
        border-radius: 10px;
        height: 5px;
      }

      .content-body {
        display: flex;
        gap: 16px;
        flex-direction: column;

        .bot-message_initial {
          display: flex;
          text-align: left;
          max-width: 90%;
          p {
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;
            word-break: break-word;
            display: flex;
            border-radius: 12px;
            padding: 12px;
            background-color: #f4f5f6;
            color: #121b2e;
            margin: 0;
          }
        }
        .message_suggestion,
        .message_suggestion_btn {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .user-message_suggestion {
            text-align: right;
            color: #273042;
            background-color: #e8f0fb;
            border: 1px solid #273042;
            border-radius: 22px;
            cursor: pointer;
            flex-shrink: 0;
            padding: 10px 16px;
            margin: 4px;
            font-family: Inter;
            font-size: 14px;
            font-weight: 400;
            line-height: 19.6px;

            &:hover {
              background-color: #d4e2f9;
            }

            &:active {
              background-color: #1666db;
              border: 1px solid #ffffff;
              color: #ffffff;
            }
          }
          &_btn {
            flex-direction: row-reverse;
            flex-wrap: wrap;
          }
        }
        .message_input {
          display: flex;
          flex-direction: column;
          align-items: flex-end;

          .user-message_input {
            align-self: flex-end;
            text-align: right;
            max-width: 90%;
            display: flex;
            padding: 12px 0;
            gap: 10px;

            p {
              background-color: #d4e2f9;
              word-break: break-word;
              text-align: left;
              font-family: Inter;
              font-size: 14px;
              font-weight: 400;
              line-height: 19.6px;
              color: #121b2e;
              margin: 0;

              display: flex;
              border-radius: 12px;
              padding: 12px;
            }
          }
          .bot-message,
          .bot-message_api,
          .bot-message_insights,
          .bot-message_response_insights,
          .bot-message_compare_insights {
            display: flex;
            text-align: left;
            padding: 12px 0;
            max-width: 90%;
            align-self: flex-start;

            p {
              font-family: Inter;
              font-size: 14px;
              font-weight: 400;
              line-height: 19.6px;
              word-break: break-word;
              border-radius: 12px;
              padding: 12px;
              background-color: #f4f5f6;
              color: #121b2e;
              margin: 0;
            }
          }
        }
      }
    }
  }

  .footer-chatbox {
    display: flex;
    flex-direction: column;
    padding: 16px 16px 16px 0;
    background-color: #ffff;
    position: absolute;
    bottom: 0px;

    .content-footer {
      display: flex;
      padding: 16px;
      background-color: #ebecee;
      border-radius: 12px;
      justify-content: space-between;
      align-items: flex-end;

      > textarea {
        font-family: Inter;
        font-size: 14px;
        font-weight: 400;
        line-height: 24px;
        overflow-y: scroll;
        align-content: center;
        width: 80%;
        border: none;
        outline: none;
        resize: none;
        min-height: 40px;
        max-height: 140px;
        flex-shrink: 0;

        &::-webkit-scrollbar {
          width: 4px;
          max-height: 20px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          background-color: #9b9a9b;
          border-radius: 10px;
        }
      }

      .button-send-message {
        width: 40px;
        height: 40px;
        border: none;
        background-color: #1666db;
        border-radius: 50%;
        cursor: pointer;
        flex-shrink: 0;
        box-shadow: 0px 8px 12px 0px #1666db29;

        img {
          padding: 5px;
        }

        &:hover {
          background-color: #1252af;
        }
        &:active {
          background-color: #0d3d83;
        }
      }
    }
    .footer-message {
      display: flex;

      p {
        padding-top: 16px;
        font-family: Inter;
        font-size: 12px;
        font-weight: 400;
        line-height: 20.4px;
        text-align: center;
        color: #565d6b;
      }
    }
  }
}
