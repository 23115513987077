.sidebar-notifications {
  button {
    padding: 0;
    width: 44px;
    height: 44px;

    span {
      padding: 0;
    }
    svg {
      path {
        fill: transparent;
        stroke: #1666db;
        stroke-width: 2;
      }
      width: 20px;
      height: 20px;
      padding: 10px;
      border: 2px solid #1666db;
      border-radius: 50%;
      margin: 0;
    }

    .user-notifications-button__count {
      background-color: #111b2f;
    }
  }

  > div {
    width: 308px;

    .gdp-notifications-empty-card__message {
      white-space: normal;
    }
  }
}
