@import '@gupy/design-system/Styles/variables/index';

.diversity-profile {
  padding: 40px 0 0;
  font-size: $font-size-base;

  &__section {
    display: inline-flex;
    align-items: center;
  }
  &__title {
    margin-top: $margin-md;
  }
  &__description {
    margin-top: $margin-sm;
    margin-bottom: $margin-md;
    font-size: $font-size-lg;
    @media only screen and (max-width: $screen-xs) {
      font-size: $font-size-base;
    }
  }
  &__icon {
    margin-left: 0.5rem;
  }
}

.share-button {
  &__tooltip {
    max-width: 170px;
  }
}
