@import '@gupy/design-system/Styles/variables/index.scss';

.system-alert {
  width: 100vw;
  .instability-alert {
    &--success {
      background-color: $color-accent-green-light;
    }
  }

  &__text-message {
    line-height: 19px;
  }

  &__more-info-link {
    text-decoration: underline;
    cursor: pointer;
    font-weight: bold;
    color: $color-blackboard;
    margin-left: $margin-xs;
  }
}
