@import '@gupy/design-system/Styles/variables/index';

.interview-event-menu {
  display: flex;
  width: 100%;

  @media (max-width: $screen-xs) {
    justify-content: space-between;
  }

  .menu-button {
    &.root.icon-button {
      color: $color-gupy-blue;
      height: auto;
      width: auto;
      padding: $padding-xs;
      margin-left: $margin-xs;

      &:focus,
      &:hover {
        background: $color-gupy-blue-light;
      }

      svg {
        font-size: $font-size-lg;
      }
    }
  }
}
