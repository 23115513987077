@import '@gupy/design-system/Styles/variables/index';

.group-type {
  padding: 0 0 $padding-lg;

  &__select-label {
    margin-right: $margin-sm;
  }

  &__divisor {
    margin: 12px 0 18px;
  }

  &__select {
    .group-type__select-list {
      margin-bottom: $margin-xs;
    }
  }

  &__hint-link span {
    color: $color-gupy-blue-secondary;
    font-weight: 700;
    text-decoration: underline;
  }
}
