@import '@gupy/design-system/Styles/variables/index';

.salary-range {
  // Overriding Front-Commons disabled style to adapt figma
  &.form-group {
    input {
      height: 48px;
      width: 150px;
    }

    input:disabled:not([type='checkbox']):not([type='radio']) {
      background-color: $color-snow;
      border: 1px solid $color-polar;
      color: $color-smoke;
      opacity: 1;

      &::placeholder {
        color: $color-smoke;
      }
    }

    &.hiringInformation {
      input {
        width: 210px;
      }
    }
  }

  &__disabled {
    color: $color-steel;
  }

  &__group-label {
    display: flex;
    margin: 10px 0;
  }

  &__label {
    font-size: $font-size-lg;
    color: $color-blackboard;
    margin: 2px $margin-sm 0 0;
  }

  &__label--disabled {
    color: $color-smoke;
  }

  &__toogle {
    &[disabled] {
      cursor: default;
      pointer-events: none;
      color: $color-smoke;
    }
  }

  &__fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  &__items {
    display: flex;
    list-style: none;
    padding: 0;

    > li {
      padding: 0;
      padding-left: 0;
    }
  }

  .dropdown {
    display: flex;

    &__button {
      &[disabled] {
        background-color: $color-snow;
        border: $border-width solid $color-polar;
      }
    }
  }

  &__values {
    max-width: 40%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    text-align: center;
    gap: $margin-sm;
  }
}
