@import '@gupy/design-system/Styles/variables/index';

.job-custom-form-template-table {
  th,
  td {
    padding: $padding-md $padding-lg;
  }

  &__index {
    margin-right: $margin-md;
    color: $color-gupy-blue-secondary;
    font-size: $font-size-lg;
    font-weight: bold;
  }
}
