@import '@gupy/design-system/Styles/variables/index';

.home-grid {
  width: 100%;

  padding: 0 $padding-sm;

  display: flex;
  flex-direction: column;

  &__title {
    font-family: $font-family;
    font-size: $font-size-lg;
    line-height: $line-height-md;
    font-weight: bold;
    align-items: center;
    color: $color-blackboard;
    padding-bottom: $padding-sm;
  }
  &__card {
    min-height: 300px;

    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
}
