@import '@gupy/design-system/Styles/variables/index';

.interview-event {
  flex: 1;

  .home-list-box-placeholder {
    &__image {
      img {
        max-width: 340px;
        width: 100%;
      }
    }
  }
}
