@import '@gupy/design-system/Styles/variables/index';

.home-list-box-placeholder {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $screen-sm-max) {
    padding: $padding-md 0;
  }

  .loading-spinner {
    position: relative;

    &__content {
      top: 0;
      margin: 0;
      width: 75px;
      height: 75px;
    }
  }

  &__label {
    padding: 0 20%;

    font-family: $font-family;
    font-size: $font-size-lg;
    line-height: $font-size-xlg;
    font-style: normal;
    font-weight: bold;
    text-align: center;

    color: $color-space-gray;
  }

  &__image {
    text-align: center;
  }
}
