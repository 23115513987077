@import '@gupy/design-system/Styles/variables/index';

.job-custom-form-template-modal [role='document'] > div {
  width: 960px;
}

.job-custom-form-template-modal {
  &__block {
    margin-bottom: $margin-lg;
  }

  &__required-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    label {
      margin-left: 0;
      margin-right: 0;
    }
  }

  &__divisor {
    border-bottom: solid 1px $color-polar;
  }

  &__textarea {
    .text-area-chip__text-area {
      padding: 10px;
    }

    &--placeholder {
      .text-area-chip__text-area {
        align-content: center;

        textarea {
          height: 72px;

          &::placeholder {
            width: 105px;
            opacity: 1;
            color: $color-steel;
            font-weight: 400;
            line-height: 24px;
          }
        }
      }
    }
  }

  &__textarea-helper {
    display: flex;
    color: $color-blackboard;
    line-height: 16px;
    margin: -$margin-sm -6px 0;
  }

  &__textarea-helper-text {
    font-size: $font-size-sm;
    margin: 0 0 0 $margin-sm;
  }

  .conditional_cards__title {
    margin: $margin-md 0;
  }

  &__clear-all-btn {
    width: 100%;
    display: flex;
    justify-content: end;
  }
}
