@import '@gupy/design-system/Styles/variables/index';

.token-management-without-other-access-permissions-dialog {
  &__title {
    font-size: $font-size-nm;
    margin-bottom: $margin-lg;
  }

  &__paragraph {
    font-size: $font-size-lg;
    margin-bottom: $margin-lg;
  }
}
