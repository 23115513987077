@import '@gupy/design-system/Styles/variables/index';

.job-boards-custom-ids {
  &__tip {
    margin-top: $margin-xl;
  }
  &__dont-show-tip-again {
    text-decoration: underline;
    font-weight: bold;
    cursor: pointer;
  }
}
