@import '@gupy/design-system/Styles/variables/index';

:root {
  --header-height: 64px;
}

$status-list: draft, waiting_approval, approved, disapproved, published, frozen,
  closed, template, canceled;
$status-colors: (
  draft: $color-steel,
  waiting_approval: $color-warning,
  approved: $color-success,
  disapproved: $color-danger,
  published: $color-gupy-blue,
  frozen: $color-steel,
  closed: $color-steel,
  template: $color-steel,
  canceled: $color-steel,
);

.new-secondary-menu {
  --logo-width: 95px;
  align-items: center;
  background-color: $color-snow;
  box-shadow: 1px 1px 4px $shadow-gupy-rest;
  box-sizing: border-box;
  display: flex;
  height: var(--header-height);
  justify-content: space-between;
  padding: 0 $padding-lg;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 100;
  @media (max-width: $screen-xs) {
    padding: 0 $padding-sm;
  }

  &__column {
    display: flex;
    flex-direction: column;
  }

  &__row {
    align-items: center;
    display: flex;
    @media (max-width: $screen-xs) {
      align-items: flex-start;
      flex-direction: column;
    }
  }

  &__brand {
    height: 40px;
    padding-right: $padding-md;
    @media (max-width: $screen-xs-max) {
      display: none;
    }
  }

  &__wrapper {
    align-items: center;
    display: flex;
  }

  &__link {
    background: transparent;
    border: none;
    &:hover {
      cursor: pointer;
    }
  }

  &__title,
  &__describe,
  &__btn {
    color: $color-space-gray;
  }

  &__btn {
    &--desktop {
      @media (max-width: $screen-xs-max) {
        display: none;
      }
    }

    &--mobile {
      display: none;
      margin-right: $margin-sm;

      @media (max-width: $screen-xs-max) {
        display: block;
      }
    }

    &--disabled {
      cursor: default;
      pointer-events: none;
      color: $color-smoke;
    }
  }

  &__drawer-footer {
    padding: $padding-lg $padding-md;

    span {
      justify-content: flex-start;
    }
  }

  &__title {
    font: {
      size: $font-size-lg;
      weight: bold;
    }
    line-height: unset;
    margin: 0;
  }

  &__describe,
  &__status {
    margin-top: $margin-xs;
  }

  &__btn {
    font: {
      family: $font-family;
      size: $font-size-lg;
    }
    white-space: nowrap;

    &:hover {
      cursor: pointer;
    }
  }

  &__status {
    font: {
      size: $font-size-sm;
      weight: bold;
    }
    margin-left: $margin-md;
    @media (max-width: $screen-xs) {
      margin-left: 0;
    }

    &::before {
      $mark-size: 8px;
      border-radius: 50%;
      content: '';
      display: inline-block;
      height: $mark-size;
      margin-right: $margin-xs;
      width: $mark-size;
    }
  }

  @each $status in $status-list {
    &__status--#{$status} {
      color: map_get($status-colors, $status);
      &::before {
        background-color: map_get($status-colors, $status);
      }
    }
  }
}
