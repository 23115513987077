@import '@gupy/design-system/Styles/variables/index';

.badge-new {
  font-size: 11px;
  background-color: $color-gupy-blue-secondary;
  color: $color-snow;
  margin: 0 7px;
  padding: 3px 8px 5px 8px;
  border-radius: 11px;
  font-weight: bold;
}

.section-item--not-permission .section-item--selected {
  background-color: $color-polar-light;
}

.children-content {
  color: $color-space-gray;
}

#company-plan-item {
  margin-top: $padding-lg;
}
