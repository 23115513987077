@import '@gupy/design-system/Styles/variables/index';

.hiring-information-modal {
  // necessary because it has to be lower than date picker z-index
  z-index: 1200 !important;

  .form-group {
    label {
      margin-bottom: $margin-sm;
    }

    input[name='hiringDate'] {
      height: 48px;
    }
  }

  .dialog-material__content {
    width: 550px;
    min-height: initial;

    @media (max-width: $screen-sm-max) {
      width: initial;
    }
  }

  .datepicker-input-wrapper {
    .material-icons.icon-material {
      background-color: $color-polar-extra-light;
      border-left: $border-width solid $color-smoke-extra-light;
      color: $color-space-gray;
      margin-left: -50px;
    }
  }

  &__loading {
    display: flex;
    justify-content: center;
  }

  textarea {
    resize: none;
    &:focus {
      border-color: $color-gupy-blue;
      box-shadow: 0 0 0 2px $color-translucent-gupy-blue;
      outline: none;
    }
  }
}
