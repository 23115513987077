@import '@gupy/design-system/Styles/variables/index';

.interview-events-list {
  &__item-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;

    padding: $padding-xs $padding-md;

    font-family: $font-family;
    font-style: normal;
    white-space: nowrap;

    color: $color-blackboard;

    width: 30%;
    @media (max-width: $screen-sm-max) {
      width: 100%;
    }
  }

  &__profile-button.root {
    @media (max-width: $screen-sm-max) {
      align-self: flex-end;
      margin-bottom: $padding-xs;
    }
  }

  &__name-label {
    font-weight: bold;

    @media (max-width: $screen-sm-max) {
      max-width: none;
      flex: 1;
    }

    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__job-name-label {
    font-weight: normal;

    @media (max-width: $screen-sm-max) {
      max-width: none;
      flex: 1;
    }

    overflow: hidden;
    text-overflow: ellipsis;

    padding-bottom: $padding-sm;
  }

  &__details-label {
    font-weight: normal;
    overflow: hidden;
    text-overflow: ellipsis;

    color: $color-space-gray;
  }
}

.avatar.avatar__large {
  width: 57px;
  height: 57px;

  @media (max-width: $screen-sm-max) {
    display: none;
  }
}
