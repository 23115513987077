@import '@gupy/design-system/Styles/variables/index';

.home-list.root {
  width: 100%;
}

.home-list {
  &__container {
    flex-direction: column;
    display: flex;
    flex: 1;
    padding: 0 $padding-sm;
    height: 100%;
  }

  @media (max-width: $screen-sm-max) {
    width: auto;
  }

  ::-webkit-scrollbar {
    width: 6px;
  }

  ::-webkit-scrollbar-track {
    border-radius: $border-radius;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: $border-radius;
    background: $color-smoke-light;
  }

  &__title {
    font-family: $font-family;
    font-size: $font-size-lg;
    line-height: $line-height-md;
    font-weight: bold;
    align-items: center;
    color: $color-blackboard;
    padding-bottom: $padding-sm;
  }

  &__fixed-content {
    position: relative;
    overflow-x: hidden;
    display: flex;
    min-height: 410px;

    flex: 1;
    width: calc(100% - 6px);

    padding: 3px;
    margin: 0 0 $margin-lg 0;
    top: -2px;

    @media (max-width: $screen-sm-max) {
      right: 3px;
      width: 100%;
    }

    border-radius: $border-radius;
    border: 0;
  }

  &__resizable-content.root {
    padding: 0;
    margin: 0;

    width: inherit;

    @media (min-width: $screen-md-min) {
      position: absolute;
    }
  }

  &__list-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }
  }
}
