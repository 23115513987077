@import '@gupy/design-system-v2/styles/tokens';

.job-board-panel-rsc-child-apps__table-row {
  &:has(+ .job-board-panel-rsc-child-apps__table-row-tip) {
    border-bottom: none;
  }

  &-actions {
    gap: $ds-spacing-lg;
  }

  &.READY_TO_CONNECT_WITH_LINKEDIN,
  &.ENABLING_LINKEDIN_FEATURES,
  &.RESTARTING_CHILD_APP_SYNC {
    .actions {
      text-align: center;
      color: $ds-color-gray-space-gray;

      .spinner {
        margin-right: $ds-spacing-sm;
        color: $ds-color-gray-space-gray;
      }
    }
  }

  &.READY_TO_CONNECT_WITH_LINKEDIN {
    .actions {
      .clickable {
        color: $ds-color-primary-blue;
        font-weight: $ds-font-weight-semibold;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          color: $ds-color-primary-blue-dark;
        }
      }
    }

    .rsc_integration_child_app_ready_to_connect_text {
      display: block;
      margin-bottom: $ds-spacing-xl;
    }
  }

  &.SYNC_PENDING {
    .spinner {
      margin-right: $ds-spacing-sm;
      color: $ds-color-primary-blue;
    }
  }
}

.job-board-panel-rsc-child-apps__table-row-edit-options {
  .actions {
    text-align: right;
  }
}

.job-board-panel-rsc-child-apps__table-row-tip {
  .actions {
    .clickable {
      font-weight: $ds-font-weight-semibold;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: $ds-color-gray-dark;
      }
    }
  }
}

.job-board-panel-rsc-child-apps__table-row {
  td {
    vertical-align: top;
  }
}

.job-board-panel-rsc-child-apps__table-row,
.job-board-panel-rsc-child-apps__table-row-edit-options {
  td {
    padding: $ds-spacing-xl $ds-spacing-md;
  }
}

.job-board-panel-rsc-child-apps__table-row-tip {
  td {
    padding: 0 $ds-spacing-md $ds-spacing-xl $ds-spacing-md;
  }
}

.child-app-sync-modal,
.child-app-desync-modal {
  p {
    margin-bottom: $ds-spacing-md;

    &:last-child {
      margin-bottom: 0;
    }
  }
}
