@import '@gupy/design-system/Styles/variables/index';

.user-access-profile {
  margin-top: $margin-xs;

  &__profile-title {
    margin-bottom: $padding-lg;
    label {
      font-size: $font-size-lg;
    }
  }

  .grid__main-content__header {
    line-height: $line-height-lg;
  }

  &__actions {
    display: flex;
    justify-content: space-between;

    @media (max-width: $screen-sm) {
      flex-direction: column;
    }
  }

  &__search,
  &__group-type-filter,
  &__new-btn {
    margin-right: $margin-md;
    width: 100%;

    @media (max-width: $screen-sm) {
      margin-bottom: $margin-md;
      width: 100%;
    }
  }

  &__search {
    min-width: 52%;
  }

  &__group-type-filter {
    min-width: 25%;

    > .select-list {
      margin-bottom: 0;
    }
  }

  &__group-type-list {
    button#group-type {
      height: 40px;
    }
  }

  &__inner-loading {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh;
  }

  &__column {
    &-grid {
      margin: 0;
      display: grid;
      grid-template-rows: 1fr auto;
      margin-bottom: 10px;
      break-inside: avoid;
    }

    @media only screen and (max-width: $screen-md) {
      width: 100%;
      padding: 1% 1% 0 0;
    }
  }

  &__footer {
    clear: both;
    margin-top: 40px;
  }

  &__divider {
    padding: 20px 0;
  }

  &__tip {
    margin-top: $margin-lg;
  }

  &__permission-nodes {
    column-count: 3;
    column-gap: 10px;

    &--diversity {
      column-count: 1;
      margin-top: $margin-md;

      > .user-access-profile__column-grid {
        max-width: 50%;

        @media only screen and (max-width: $screen-lg) {
          max-width: 100%;
        }
      }
    }

    &:not(.user-access-profile__permission-nodes--diversity) {
      @media only screen and (max-width: $screen-lg) {
        column-count: 2;
      }
    }

    @media only screen and (max-width: $screen-md) {
      column-count: 1;
    }
  }

  div[role='tabpanel'] {
    width: initial !important;
    overflow-y: initial !important;
  }
}
