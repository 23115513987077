@import '@gupy/design-system/Styles/variables/index';

.custom-id-card {
  margin-bottom: $margin-md;
  &__container {
    align-items: flex-start;
    flex-direction: row;
  }
  &__id-column,
  &__career-pages-column,
  &__remove-btn-column {
    padding-bottom: $padding-md;
  }
  &__career-pages-column {
    .dropdown__label {
      padding-top: 0;
    }
    .select-list {
      &__container-button.root {
        height: 40px;
      }
      &__label-element {
        font-size: $font-size-base;
        line-height: 1;
      }
    }
  }
  &__how-to-find-custom-id-hyperlink {
    text-decoration: underline;
    font-weight: bold;
  }
  &__remove-btn-column {
    align-self: center;
    text-align: right;
    @media (min-width: $screen-sm) {
      text-align: center;
    }
  }
  &__remove-btn-mobile.btn {
    display: inline-flex;

    &.gupy-button--lg.root {
      padding-right: 0;
    }
    @media (min-width: $screen-sm) {
      display: none;
    }
  }
  &__remove-btn-desktop.btn {
    display: none;
    @media (min-width: $screen-sm) {
      display: inline-flex;
    }
  }
}
