@import '@gupy/design-system/Styles/variables/index';

.user-access-profile-card {
  margin: 0;

  &__content {
    display: flex;
    justify-content: space-between;
    margin-bottom: $margin-sm;
  }

  &__group-type {
    margin-bottom: $margin-sm;

    span:first-child {
      margin-right: $margin-sm;
    }
  }

  &__diversity {
    margin-bottom: $margin-sm;
  }

  &__title {
    cursor: pointer;
    font-size: $font-size-lg;
    font-weight: bold;
    color: $color-blackboard;

    &-disabled {
      cursor: default;
    }
  }

  &__subtitle {
    color: $color-steel;
    font-size: $font-size-sm;
  }
}
