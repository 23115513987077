@import '@gupy/design-system/Styles/variables/index';

.conditional-card {
  display: flex;
  justify-content: space-between;

  &.card {
    margin-bottom: $margin-sm;
  }

  &__title {
    font-size: $font-size-base;
    font-weight: 700;
    margin: $margin-sm 0 10px;
  }

  &__index {
    color: $color-gupy-blue-secondary;
    margin-right: $margin-xs;
  }

  &__info {
    font-size: $font-size-base;
    display: flex;
  }

  &__type {
    font-weight: 600;
  }

  &__icon {
    margin: 0 $margin-xs 0 $margin-sm;
  }
}
