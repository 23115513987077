@import '@gupy/design-system/Styles/variables/index';
@import '@gupy/design-system-v2/styles/tokens';

.job-board-panel-rsc {
  &__card {
    color: $color-space-gray;
    display: flex;
    flex-direction: column;
    margin-top: $margin-xl;

    &--title {
      font-size: $font-size-lg;
      margin-bottom: $margin-lg;
      text-align: left;
    }

    &--sub-title {
      padding-bottom: $padding-md;
      text-align: left;
      font-size: $font-size-lg;
      line-height: $line-height-xs;

      &--link {
        color: $ds-color-primary-blue;
        font-weight: $ds-font-weight-semibold;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          color: $ds-color-primary-blue-dark;
        }
      }
    }
  }
}
