@import '@gupy/design-system-v2/styles/tokens';

.job-board-panel-rsc-child-apps__name-on-edit-mode {
  > div {
    > div {
      display: none;
    }

    span:nth-of-type(2) {
      color: $ds-color-alert-danger;
    }
  }
}
