@import '@gupy/design-system/Styles/variables/index';

.group-type-new-dialog {
  color: $color-space-gray;
  letter-spacing: 0;

  &__title {
    letter-spacing: 0;
  }

  &__description-item {
    letter-spacing: 0;
    margin-left: $margin-sm;
    color: $color-space-gray;

    &--first {
      margin-top: $margin-md;
      margin-bottom: $margin-sm;
    }
  }

  &__description-topic {
    font-weight: 700;
  }
}
