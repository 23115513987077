@import '@gupy/design-system/Styles/variables/index';

.job-board-panel {
  &__title {
    color: $color-blackboard;
    font-size: $font-size-lg;
    margin-bottom: $margin-sm;
  }

  &__sub-title {
    color: $color-blackboard;
    font-size: $font-size-lg;
    padding-bottom: $padding-md;
    line-height: $line-height-xs;
  }

  &__add-btn.btn {
    &.gupy-button--lg.root {
      padding: $padding-md;
    }

    @media (min-width: $screen-sm) {
      width: inherit;
    }
  }
}
