@import '@gupy/design-system/Styles/variables/index';

.no-permission {
  margin-top: $margin-sm;
  color: $color-steel;
  display: flex;
  align-items: center;

  &__icon {
    margin-right: $margin-xs;
    align-self: flex-start;
  }

  svg {
    font-size: $font-size-lg;
  }
}
