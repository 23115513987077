@import '@gupy/design-system/Styles/variables/index';

.permission-diversity-confirm-dialog-paragraph {
  margin-top: $margin-lg;
  font-size: $font-size-lg;
}

.permission-diversity-confirm-dialog-tip {
  font-size: $font-size-base;
  @media only screen and (max-width: $screen-xs - 60) {
    margin-top: $margin-lg;
  }
}
