@import '@gupy/design-system/Styles/variables/index';

.job-deletion {
  &__tip {
    margin: $margin-md 0;
  }

  &__button {
    align-self: flex-end;
  }
}

.job-deletion-modal {
  &__info-title {
    font-size: $font-size-lg;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding: $padding-lg 0 0;
    align-items: center;
    -webkit-box-align: center;
    -webkit-box-pack: end;

    &--submit {
      &:nth-child(2) {
        margin-left: $margin-lg;
        margin-bottom: $margin-sm;
        background-color: $color-danger;
      }
    }
  }
}

.job-deletion-reason {
  margin-top: $margin-lg;
  margin-bottom: -$margin-sm;

  &__container {
    display: flex;
    flex-direction: column;
    max-width: 100%;
  }

  &__textarea-container {
    margin-top: $margin-md;
    margin-bottom: -14px;

    label {
      font-size: $font-size-base !important;
    }
  }
}

.job-deletion-feedback {
  &__template-preview {
    margin-top: $margin-md;
  }
}

.job-deletion-confirm {
  margin-top: -$margin-sm;
  margin-bottom: -$margin-lg;

  &__container {
    margin-top: 0;
  }

  &__tip {
    margin-bottom: 19px;
    font-size: $font-size-sm;
  }

  &__tip-title {
    font-size: $font-size-sm;
  }

  &__tip-list {
    padding: 0 0 0 10px;
  }

  &__tip-list-item {
    font-weight: bold;
  }
}

.template-email {
  &__body {
    img {
      max-width: 100%;
    }
  }
}
