@import '@gupy/design-system-v2/styles/tokens';

.job-board-panel-rsc-child-apps__delete-in-linkedin-dialog {
  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: $ds-spacing-md;
    margin-bottom: $ds-spacing-lg;

    span {
      font-size: $ds-font-size-nm;
      font-weight: $ds-font-weight-bold;
    }

    .linear-progress {
      margin: 0px;

      &__description {
        margin: 0px;
      }

      &__bar--solid {
        background-color: $ds-color-primary-blue-dark;
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: $ds-spacing-md;
    min-height: 180px;

    .tip--inverted.tip--danger {
      color: $ds-color-gray-blackboard;
    }

    .email-template-tip {
      .email-template-tip-link {
        margin-left: $ds-font-size-xlg + $ds-font-size-xs;
        color: $ds-color-primary-blue;
        font-weight: $ds-font-weight-bold;
        text-decoration: underline;
      }
    }

    .email-template-section {
      font-size: $ds-font-size-base;

      .email-template-copy {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      .email-template {
        border-color: $ds-color-gray-silver;
        background-color: $ds-color-gray-polar-extra-light;
        padding: $ds-spacing-md;
        margin-bottom: $ds-spacing-sm;
      }

      .checkbox-label-wrapper {
        font-size: $ds-font-size-base;
      }

      .checkbox {
        margin-bottom: 0px;
      }
    }
  }
}
