@import '@gupy/design-system/Styles/variables/index';
@import '@gupy/design-system-v2/styles/tokens';

.job-board-panel-rsc-create-child-app {
  &__button-wrapper {
    text-align: center;
    margin-top: $padding-lg;
  }
  &__description {
    padding-bottom: $padding-md;
    text-align: left;
    font-size: $font-size-lg;
    line-height: $line-height-xs;

    &-link {
      color: $ds-color-primary-blue;
      font-weight: $ds-font-weight-semibold;
      text-decoration: underline;
      cursor: pointer;

      &:hover {
        color: $ds-color-primary-blue-dark;
      }
    }
  }
}
