@import '@gupy/design-system/Styles/variables/index';

.user-notifications-button {
  position: relative;
  &__count {
    $count-size: 7px;
    $count-gap: 2px;
    background-color: $color-warning;
    border: $border-width solid $color-snow;
    border-radius: 100%;
    display: inline-block;
    height: $count-size;
    position: absolute;
    right: $count-gap;
    top: $count-gap + 2;
    width: $count-size;
  }

  svg {
    color: $color-blackboard;
    font-size: $font-size-xlg !important;

    &:hover {
      color: $color-space-gray;
    }
  }
}
