@import '@gupy/design-system/Styles/variables/index';

.job-custom-form-template-menu-button {
  display: flex;

  &__btn {
    padding: 0px $padding-xs;

    @media (max-width: $screen-md-max) {
      padding: 0 0 0 $padding-md;
    }

    span {
      color: $color-gupy-blue;
    }

    .menu-button.icon-button {
      &:focus,
      &:hover {
        background: $color-gupy-blue-light;
      }
    }
  }
}
