@import '@gupy/design-system/Styles/variables/index.scss';

.test-time-limit {
  .form-group input {
    font-weight: normal;
    color: $color-space-gray;

    &::placeholder {
      color: $color-steel;
    }
  }

  #timeLimit:disabled {
    opacity: 1;
    background-color: $color-polar-extra-light;
    border-color: $color-smoke-extra-light;
    height: 40px;
  }

  #timeLimit {
    height: 40px;
  }
}

.custom-test-form {
  &__view-only-tip {
    margin-top: $margin-xl;
  }

  &__infotip-message {
    margin-right: $margin-sm;
    color: $color-space-gray;
    font-size: $font-size-base;
  }

  &__infotip-label {
    display: flex;
    align-items: center;
  }

  &__random-questions-infotip-label {
    display: flex;
    align-items: center;
  }

  &__random-questions-infotip-message {
    margin-right: $margin-sm;
    color: $color-space-gray;
    font-size: $font-size-base;
  }

  .row {
    margin-top: $margin-lg;
  }

  .infotip {
    color: $color-space-gray;
  }

  input:disabled {
    color: $color-space-gray;
    background-color: $color-polar-extra-light;
    border-color: $color-smoke-extra-light !important; //Existe um !important no estilo do componente
  }

  .texteditor .ql-container.ql-snow.ql-disabled {
    opacity: 1;
    background-color: $color-polar-extra-light;

    .ql-editor p {
      color: $color-steel;
      font-size: $font-size-lg;
    }
  }
}
