@import '@gupy/design-system/Styles/variables/index';

.authentication-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  &__main {
    padding: $padding-lg;

    width: 542px;
    @media (min-width: $screen-xs) {
      max-width: 340px;
    }
  }

  &__container {
    background-color: #f4f5f6;

    width: 100%;
    min-height: 88vh;

    display: flex;
  }

  &__content {
    background-color: $color-snow;

    min-height: 100%;
    width: 100%;
    min-width: 340px;

    z-index: 1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media (min-width: $screen-lg) {
      width: 542px;
    }
  }

  &__background {
    display: none;
    margin: 0;
    z-index: 0;

    @media (min-width: $screen-lg) {
      background-repeat: no-repeat;
      background-position: top;
      background-size: auto 100%;
      display: flex;
      flex: 1;
    }
  }

  &__footer {
    display: flex;
    width: 100%;
  }
}

.authentication-layout-rebranding {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  &__main {
    padding: $padding-lg;

    width: 542px;
    @media (min-width: $screen-xs) {
      max-width: 340px;
    }
  }

  &__container {
    background-color: #f4f5f7;

    width: 100%;

    display: flex;
    min-height: 88vh;
  }

  &__content {
    background-color: $color-snow;

    min-height: 100%;
    width: 100%;
    min-width: 340px;

    z-index: 1;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    @media (min-width: $screen-lg) {
      width: 542px;
    }
  }

  &__background {
    display: none;
    margin: 0;
    z-index: 0;

    @media (min-width: $screen-lg) {
      background-repeat: no-repeat;
      background-position: top;
      background-size: auto 100%;
      display: flex;
      flex: 1;
    }
  }
  &__footer {
    display: flex;
    width: 100%;
  }
}
