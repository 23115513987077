@import '@gupy/design-system/Styles/variables/index';

.terms-and-privacy {
  text-align: justify;
  font-size: $font-size-base;
  line-height: 16px;

  p {
    color: $color-space-gray;
  }
}
