@import '@gupy/design-system/Styles/variables/index';

.custom-test-questions {
  &__view-only-tip {
    margin: $margin-xl 0px;
  }

  &__static-image {
    display: block;
    background-size: cover;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    border: $border-width solid $color-silver;
    background-color: $color-polar-extra-light;
  }
}
