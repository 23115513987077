@import '@gupy/design-system-v2/styles/tokens';

.child-app-desync-modal {
  p {
    display: flex;
    gap: $ds-spacing-xs;

    &:first-child {
      color: $ds-color-alert-danger;

      .infotip {
        margin-right: $ds-spacing-md;
      }
    }
  }
}
