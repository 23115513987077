@import '@gupy/design-system/Styles/variables/index';

#job-closure,
.job-closure {
  &__tip {
    max-width: 468px;
    align-items: center;
    margin: 10px 0;
  }

  &__modal {
    justify-content: center;

    &__header {
      border-top-left-radius: 2px;
      border-top-right-radius: 2px;
    }

    &__body {
      padding: 10px 16px;
      min-width: 540px;
      min-height: 276px;

      @media (max-width: $screen-xs-max) {
        padding: 30px 0;
        min-width: 0;
        min-height: 0;

        .hidden-mobile {
          @media only screen and (max-width: $screen-xs-max) {
            display: none;
          }
        }
      }

      .vertical-linear-stepper {
        display: flex;
        flex-direction: column;

        .clickable {
          cursor: pointer;
        }

        .form-group {
          margin-bottom: 8px;
          margin-top: 16px;
        }

        .Select-menu-outer {
          position: relative;
        }

        .Select {
          width: 270px;
        }

        .Select-control {
          border-radius: 2px;
          box-shadow: 0 1px 3px $color-translucent-black-1;
        }

        .validation {
          color: $color-danger;
          margin-top: 16px;
          visibility: hidden;

          &.show {
            visibility: visible;
          }
        }

        &__label {
          font-weight: bold;
        }

        &__actions {
          padding: 0px 2px;
        }

        &__buttons {
          @media (max-width: 600px) {
            display: flex;
            flex-wrap: wrap;
          }

          text-align: right;
          margin: 14px auto;

          & * + * {
            margin-left: 24px;
          }
        }

        &__button {
          @media (max-width: 600px) {
            text-align: center;
            width: 100%;
            margin: 10px 0 0 0;
            line-height: 1.5rem;

            &:first-child {
              order: 1;
            }
          }
        }
      }

      .template-email {
        margin: $margin-sm 0;
        max-width: 500px;
      }
    }

    .modal-paper {
      min-width: 200px;
      min-height: 300px;
      border-radius: 2px;

      &:focus {
        outline: none;
      }

      @media (max-width: $screen-xs-max) {
        top: 8px;
        left: 8px;
        right: 8px;
        bottom: 8px;
        transform: none;
        margin: 0;
      }

      .loading-spinner {
        background-color: transparent;

        &__content {
          margin: 50px;
          top: 40%;
        }
      }
    }
  }
}
