@import '@gupy/design-system/Styles/variables/index';

.grid-sidebarV2 {
  display: flex;
  flex-direction: column;
  padding: 0 $padding-xl;

  @media (max-width: $screen-md) {
    max-width: 25%;
    flex: 0 0 auto;
    box-sizing: border-box;
    flex-basis: 25%;
  }

  @media (max-width: $screen-sm) {
    display: none;
  }

  &__title {
    color: $color-blackboard;
    margin-bottom: $margin-sm;
    line-height: 29px;
    font-size: $font-size-lg;
    font-weight: normal;
  }

  &__description {
    font-size: $font-size-lg;
    margin-bottom: $margin-sm;
  }

  .section-item {
    &:hover {
      font-weight: 400;
    }
  }
}
