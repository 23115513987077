@import '@gupy/design-system/Styles/variables/index';

.conditional-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__description {
    font-size: $font-size-lg;
    color: $color-space-gray;
    font-weight: bold;
  }

  &__select {
    margin-bottom: 0px !important;
    min-width: 280px;
  }
}
