@import '@gupy/design-system/Styles/variables/index';

.job-templates {
  &__block {
    margin-bottom: $margin-lg;
  }

  &__loading {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  &__row {
    text-align: center;
    margin-bottom: $margin-lg;
    width: 100%;
  }

  &__create-button {
    @media (max-width: $screen-xs-max) {
      margin-bottom: $margin-md;
    }
  }
}
