@import '@gupy/design-system/Styles/variables/index';

.conditional-form {
  &__title {
    font-size: 18px;
    color: $color-blackboard;
    margin: 0 0 $margin-md;
    line-height: 24px;
  }

  &__label {
    font-size: $font-size-lg;
    color: $color-space-gray;
    line-height: 16px;
  }

  &__field {
    &--second {
      margin: -14px 0 $margin-md;
    }

    &--last {
      margin: -14px 0 30px;
    }

    .select-list__label,
    input::placeholder {
      font-family: $font-family;
      font-size: $font-size-lg;
      font-weight: 400;
      color: $color-space-gray;
      line-height: 18px;
    }
  }
}
