@import '@gupy/design-system/Styles/variables/index';

.footer-content {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  padding: $padding-lg $padding-md;
  background-color: #111b2f;
  width: 100%;

  @media (min-width: 770px) {
    flex-direction: row;
    gap: 8rem;
    padding: $padding-xl;
    background-image: url('../../../Authentication/components/Footer/images/seta-footer.svg');
    background-repeat: no-repeat;
    background-position: right;
    background-size: contain;
    position: relative;
  }

  &__body {
    display: flex;
    flex-grow: 2;
    flex-wrap: wrap;
    justify-content: space-between;

    @media (max-width: 770px) {
      gap: 2.4rem;
    }

    section {
      @media (max-width: 770px) {
        width: 100%;
      }

      h3 {
        font-size: $font-size-lg;
        padding-bottom: 8px;
        color: $color-snow;
        line-height: $line-height-xs;
        height: 40px;
        margin: 0;
      }

      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }

      li {
        padding: $padding-xs 0;

        @media (max-width: 770px) {
          padding: $padding-md 0;
        }
      }
      a {
        color: $color-snow;
        font-size: $font-size-base;
        cursor: pointer;

        :visited {
          color: $color-snow;
        }
        :hover {
          text-decoration: underline $color-snow;
        }
        :focus {
          border: 1px $color-snow;
          border-radius: $border-radius;
        }
      }
    }
  }

  &__message {
    font-size: $font-size-base;
    color: #c0c3c8;

    @media (min-width: 770px) {
      position: absolute;
      right: 0;
      bottom: 0;
      margin: 0 3.2rem 3.2rem 0;
    }
  }
}
