@import '@gupy/design-system/Styles/variables/index';

.knowMoreDialog {
  color: $color-space-gray;
  letter-spacing: 0;

  &__title {
    letter-spacing: 0;
  }

  &__description-list {
    margin-top: $margin-md;
    margin-bottom: 0;
    padding-left: $padding-lg;
  }

  &__description-item {
    color: $color-space-gray;
    padding: 0;

    &--first {
      padding-bottom: $padding-xs;
    }
  }
}
