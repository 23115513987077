@import '@gupy/design-system/Styles/variables/_colors.scss';
@import '@gupy/design-system/Styles/variables/_fonts.scss';
@import '@gupy/design-system/Styles/variables/_spacings.scss';
@import '@gupy/design-system/Styles/variables/_breakpoints.scss';

.diversity-term__heading {
  margin-top: $margin-xl;
  margin-bottom: $margin-md;
  font-size: $font-size-xlg;
  line-height: 1.5;
  color: $color-blackboard;
}

.term__title {
  margin-top: 0;
  margin-bottom: $margin-xl;
  font-size: $font-size-lg;
  line-height: 1.5;
  text-align: center;
  text-transform: uppercase;
  color: $color-blackboard;
}

.term__subtitle {
  margin-top: 0;
  margin-bottom: $margin-xl;
  font-size: $font-size-lg;
  line-height: 1.5;
  text-align: left;
  text-transform: uppercase;
  color: $color-blackboard;
}

.diversity-term__paragraph {
  margin-bottom: $margin-xl;
  color: $color-blackboard;
  font-size: $font-size-lg;
  line-height: 1.5;

  b {
    font-weight: bold;
    text-transform: uppercase;
  }
}
.diversity-term__footer > .diversity-term__paragraph {
  margin-bottom: $margin-sm;
}

.term__list {
  margin-bottom: $margin-xl;

  .diversity-term__paragraph {
    margin-bottom: 0;
  }
}

.diversity-term__link {
  text-decoration: underline;
  color: inherit;
}

.diversity-term__button {
  margin: 0;
}

.diversity-term__list {
  margin-top: $margin-xl;
  padding-left: $padding-lg;
}
