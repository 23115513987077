@import '@gupy/design-system/Styles/variables/index';
@import '@gupy/design-system-v2/styles/tokens';

.gupy-table {
  border: none;
  margin-bottom: $margin-lg;

  tr:last-child {
    border-bottom: solid $border-width $ds-color-gray-smoke-extra-light;
  }
}
.label-column-name {
  display: flex;
  align-items: center;

  &__infotip-rsc-integration-column-name {
    margin-left: $padding-xs;
  }
}
