@import '@gupy/design-system/Styles/variables/index';

.integrations {
  .error-detail {
    display: none;
  }
  .info-field {
    font-size: $font-size-lg;
    color: $color-space-gray;
    margin-bottom: 45px;
  }
  .boolean_field__label_enabled {
    color: $color-gupy-blue !important;
    font-size: $font-size-lg;
    text-transform: uppercase;
  }
  .boolean_field__label_disabled {
    color: $color-smoke-extra-light !important;
    font-size: $font-size-lg;
    text-transform: uppercase;
  }
  .toolbar {
    border-top: 0;
    border-bottom: 0;
    display: flex;
    justify-content: flex-end;
    .job-boards-custom-ids__save-btn {
      @media (min-width: $screen-sm) {
        width: inherit;
      }
    }
  }
  hr {
    margin-top: 40px;
  }
  .control-label {
    color: $color-blackboard;
    padding: 10px 0;
  }
}

.spinner {
  align-items: center;
  display: flex;
  height: 500px;
  justify-content: center;
}
