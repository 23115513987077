@import '@gupy/design-system/Styles/variables/index';

.custom-form-save {
  display: flex;
  justify-content: space-between;
  margin: $margin-xl 0;

  @media (max-width: $screen-md) {
    flex-wrap: wrap;
    justify-content: center;
  }

  @media (max-width: $screen-sm) {
    padding: $padding-md $padding-xs;
  }

  &__content {
    display: flex;
    align-items: center;

    @media (max-width: $screen-md) {
      margin-bottom: $margin-md;
    }
  }

  &__icon {
    display: flex;
    background: $color-warning-light;
    color: $color-warning;
    border-radius: 50%;
    padding: $padding-sm;
    margin-right: $margin-md;
  }

  &__text {
    margin: 0;
    color: $color-space-gray;
  }
}
