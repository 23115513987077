@import '@gupy/design-system/Styles/variables/index';

.job-template-cards {
  height: 100%;

  &__title {
    margin-bottom: $margin-md;
    cursor: pointer;
  }

  &__body {
    margin-top: $margin-md;
    display: flex;

    span:first-child {
      margin-right: $margin-sm;
    }
  }
}
