@import '@gupy/design-system/Styles/variables/index';

.tests-correction-question {
  &__container {
    display: flex;
    justify-content: flex-end;
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: $margin-sm;

    width: 100%;
    flex: 1;

    @media only screen and (min-width: 768px) {
      width: 60%;
      flex: none;
    }

    @media only screen and (min-width: 1024px) {
      width: 50%;
      flex: none;
    }

    @media only screen and (min-width: 1366px) {
      width: 45%;
      flex: none;
    }

    @media only screen and (min-width: 1920px) {
      width: 30%;
      flex: none;
    }

    &__reverse {
      flex-direction: row-reverse;
    }

    .tests-correction-question__answer-button {
      &--incorrect {
        color: $color-danger;

        &:hover {
          color: $color-danger;
        }

        &:disabled {
          color: $color-danger;
        }
      }

      &--correct {
        color: $color-success;

        &:hover {
          color: $color-success;
        }

        &:disabled {
          color: $color-success;
        }
      }
    }
  }
}
