.copilot_feedback {
  width: 80%;
  display: flex;
  text-align: center;
  align-self: flex-start;
  justify-content: flex-end;

  .buttons-feedback {
    display: flex;
    align-items: center;

    .newReq_btn {
      cursor: pointer;
      display: flex;
      align-items: center;

      p {
        margin: 0;
        padding-left: 4px;
        padding-right: 16px;
        font-family: Inter;
        font-size: 14px;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
        color: #1666db;
      }
    }

    .like-dislike {
      gap: 4px;

      .like_btn {
        cursor: pointer;
        width: 32px;
        height: 32px;
      }
      .dislike_btn {
        cursor: pointer;
        width: 32px;
        height: 32px;
      }
    }
  }
}
