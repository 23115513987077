@import '@gupy/design-system/Styles/variables/index';

.section-item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  color: $color-gupy-blue;
  font-size: $font-size-lg;
  padding: $padding-xs;
  cursor: pointer;
  text-decoration: none;

  &:hover,
  &--selected {
    color: $color-gupy-blue-dark;
    font-weight: bold;
  }

  &--selected {
    background-color: $color-gupy-blue-light;
    border-radius: $border-radius;
  }

  &:focus {
    outline: none;
  }
}
