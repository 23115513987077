@import '@gupy/design-system/Styles/variables/index';

.job-overview-list {
  list-style: none;
  padding: 0;

  &__divisor {
    border-bottom: 1px solid $color-smoke-extra-light;
  }

  &__actions {
    display: flex;
    list-style: none;
    padding: 0;

    @media (max-width: $screen-sm) {
      flex-direction: column;
    }

    > li button,
    > .job-templates-dropdown {
      display: inline-grid;
      margin-bottom: 0;
      height: fit-content;

      .form-group {
        margin-bottom: 0;
      }
    }

    > li button {
      margin-right: 15px;
    }

    > .job-templates-dropdown {
      flex-grow: 1;
    }
  }

  &__spinner {
    width: 100%;
    margin-top: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.job-container {
  .job-overview-list__title {
    border: none;
    height: 32px;
    margin-bottom: $margin-xl;
    visibility: hidden;

    span {
      font-size: $font-size-xlg;
    }

    @media (max-width: $screen-sm) {
      visibility: visible;
    }
  }

  .job-overview-list__content {
    padding: $padding-xl;
  }
}

.job-overview-item {
  margin-bottom: $margin-xl;
  padding: 0;

  @media (max-width: $screen-sm) {
    padding-bottom: $padding-lg;
    margin-bottom: $margin-lg;
    border-bottom: $border-width solid $color-smoke-light;
  }

  &--last {
    border-bottom: none;
  }

  &__fieldset {
    border: none;
    margin: 0;
    padding: 0;
  }

  &__legend {
    display: flex;
    margin-bottom: $margin-md;
    align-items: center;
  }

  &__dropdown {
    flex: 1;
  }

  &__number {
    background-color: $color-gupy-blue-light;
    border-radius: 50%;
    color: $color-gupy-blue;
    margin: 0 $margin-md 0 0;
    min-width: 40px;
    min-height: 40px;
    text-align: center;
    line-height: 40px;
    font-weight: bold;
    font-size: $font-size-lg;

    @media (max-width: $screen-sm) {
      margin: 0 13px 0 0;
    }
  }

  &__title {
    line-height: 29px;
    font-size: $font-size-xlg;
    margin: 0;

    @media (max-width: $screen-sm) {
      line-height: $line-height-xs;
      font-size: $font-size-nm;
    }
  }

  &__description {
    color: $color-blackboard;
    margin-bottom: $margin-lg;
  }

  &__button.root {
    font-weight: 600;
    letter-spacing: 0;
    width: 170px;

    @media (max-width: $screen-sm) {
      width: 100%;
    }
  }

  &__button--first.root {
    @media (max-width: $screen-sm) {
      margin-bottom: $margin-md;
    }
  }
}
