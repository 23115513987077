@import '@gupy/design-system/Styles/variables/index.scss';

$container-width: 98%;
$container-padding: 20px;
$container-height: 97%;

.blocked-component-container {
  width: $container-width;
  height: $container-height;
  margin-left: auto;
  margin-right: auto;
  padding-top: 56px;
  padding-left: $container-padding;
  padding-right: $container-padding;
  z-index: 1000;
  justify-content: center;

  &__card {
    display: block;
    margin: 0 auto;
    width: 440px;

    & > h3 {
      margin: 0;
      color: $color-gupy-blue;
    }

    & > p {
      margin: 0;
      color: $color-space-gray;
    }
  }

  &__image-plan {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

  &__header {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    color: $color-blackboard;
  }

  .description-common {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
    padding-bottom: $padding-xl;
    color: $color-space-gray;
    width: 440px;
    line-height: $font-size-xlg;
  }

  &__description {
    @extend .description-common;
    font-size: $font-size-lg;
  }

  &__subDescription {
    @extend .description-common;
    font-size: $font-size-sm;
  }

  &__button {
    display: block !important;
    margin: 0 auto !important;
    margin-bottom: $padding-xl !important;
    text-align: center;
  }
}
